import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  registers: {
    loading: false,
    data: null,
  },
  createRegistry: {
    loading: false,
    data: null,
  },
  selectedRegistry: {
    loading: false,
    data: null,
  },
  approve: {
    loading: false,
    data: null,
  },
  updateBids: false,
}

const registersSlice = createSlice({
  name: 'registers',
  initialState,
  reducers: {
    fetch: (state, action) => {

    },
    create: (state, action) => {

    },
    fetchId: (state, action) => {

    },
    approve() {

    },
    setLoading(state, action) {
      const {payload} = action
      state[payload.type].loading = payload.loading
    },
    setRegisters(state, action) {
      const {payload} = action
      state.registers.data = payload.data
    },
    setCreateRegistry(state, action) {
      const {payload} = action
      state.createRegistry.data = payload.data
    },
    setSelectedRegistry(state, action) {
      const {payload} = action
      state.selectedRegistry.data = payload.data
    },
    setApprove(state, action) {
      const {payload} = action
      state.approve.data = payload.data
    },
    setUpdateBids(state, action) {
      const {payload} = action
      state.updateBids = payload
    }
  },
})
const registersReducer = registersSlice.reducer

export {registersSlice, registersReducer}
